<template>
  <div>
    <Heard />

    <div class="container-fluid login-box">
      <div class="container write-box">
        <el-card>
          <div>
            <router-link to="/login" style="color: #000">返回</router-link>
          </div>

          <div style="width: 160px; height: 160px" id="wx_box"></div>
        </el-card>
      </div>
    </div>
    <Otherfooter />
  </div>
</template>

<script>
import { api_sendMsg, api_authLogin } from "@/apis/apis";
import Heard from "@/components/Heard.vue";
import Otherfooter from "@/components/Otherfooter.vue";
export default {
  components: {
    Heard,
    Otherfooter,
  },

  data() {
    //手机号
    var checkmobile = (value, callback) => {
      var re = /^1\d{10}$/;
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        if (!re.test(value)) {
          callback(new Error("请输入正确的手机号"));
        }
        callback();
      }
    };
    //验证码
    var validatecode = (rule, value, callback) => {
      console.log(this.ruleForm.mobile);
      if (!value) {
        return callback(new Error("验证码不能为空"));
      } else {
        if (this.ruleForm.code != this.msgcode) {
          callback(new Error("验证码错误"));
        }
        callback();
      }
    };

    return {
      ruleForm: {
        code: "",
        mobile: "",
      },
      rules: {
        code: [{ validator: validatecode, trigger: "blur" }],
        mobile: [{ validator: checkmobile, trigger: "blur" }],
      },

      show: true,
      count: "",
      timer: null,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api_authLogin({
            mobile: this.ruleForm.mobile,
            passwd: this.ruleForm.ispwd,
            loginType: 1, // 0: 账号密码登  ，1：短信验证码登录 2微信登录
          }).then((res) => {
            console.log(res);
            if (res.data.status === true) {
              this.$store.commit("userstore/setUserInfo", res.data.data);
              this.$router.push("/");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    getCode() {
      if (this.ruleForm.mobile != "") {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
        api_sendMsg({
          mobile: this.ruleForm.mobile,
          msgType: 2,
        }).then((res) => {
          console.log(res);
          this.msgcode = res.data.data.code;
        });
      } else {
        this.$message({
          showClose: true,
          message: "请输入手机号",
          type: "error",
          offset: 60,
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      var content =
        ".impowerBox .qrcode {width: 200px;}.impowerBox .info {width: 240px;}.impowerBox .status{width:240px;padding:0;}";
      //记住一定要注明文件类型是css
      var blob = new Blob([content], { type: "text/css;charset=utf-8" });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        // var obj = new WxLogin({
        //     self_redirect: false,
        //     id: "wechat",
        //     appid: re.appid,
        //     scope: re.scope,
        //     redirect_uri: re.redirect_uri,
        //     state: re.state,
        //     style: "",
        //     href: this.result
        // });

        // const obj =
        // new WxLogin({
        //   self_redirect: false,

        //   id: "wx_box", //div的id

        //   appid: "wx3fa4c389830bde50",

        //   scope: "snsapi_login",

        //   redirect_uri: "https://813job.com", //回调地址

        //   state: "", //参数，可带可不带
        //   style: "", //样式  提供"black"、"white"可选，默认为黑色文字描述
        //   href: this.result, //自定义样式链接，第三方可根据实际需求覆盖默认样式。
        // });

        let iframe = document.getElementsByTagName("iframe")[0];
        iframe.sandbox =
          "allow-forms allow-same-origin allow-scripts allow-top-navigation";
      };
    });
  },
};
</script>

<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
}
/* 版心 */
.container {
  width: 1200px;
  margin: 0 auto;
}
.login-box {
  height: 600px;
  background: url(~@/assets/image/loginbanner.png) no-repeat center;
  .write-box {
    position: relative;
    /deep/ .el-card__body {
      padding: 0;
    }
    .el-card {
      width: 360px;
      height: 400px;
      position: absolute;
      top: 100px;
      right: 100px;
      padding: 30px 40px 34px;
    }
    .iframe {
      .impowerBox .qrcode {
        width: 200px !important;
      }
    }

    // .jumpwxlogin {
    //   width: 160px;
    //   height: 40px;
    //   background: #f8f8fa;
    //   border-radius: 100px;
    //   margin: 0 auto;
    //   text-align: center;
    //   line-height: 40px;
    //   font-size: 12px;
    //   color: #7b7b7b;
    // }
  }
}
</style>