<template>
  <div class="footer container">
    <p class="one">
      网站首页 | 关于我们 | 广告合作 | 联系我们 | 隐私条款 | 免责声明
    </p>
    <p class="two">
      <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">蜀ICP备2021001179号-1</a>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer {
  text-align: center;
  margin-top: 100px !important;
  // position: fixed;
  // left: 50%;
  // bottom: 40px;
  // transform: translateX(-50%);

  .one {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin-bottom: 15px;
    color: #000000;
    opacity: 1;
  }
  .two {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;

    color: #000000;
    opacity: 1;
  }
}
</style>